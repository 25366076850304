<template>
    <div class="init">
        <el-dialog v-model="copyShow" lock-scroll :title="$t('currencySet.text16')" width="50%" >
            <el-form :model="fromValue" label-width="auto" class="fromClass" label-position="top" ref='form_' :rules="rules">
                <h3>{{$t('currencySet.text6')}}</h3>
                <el-form-item :label="$t('currencySet.text8')" prop="zhContent1">
                    <el-input v-model="fromValue.zhContent1" type="textarea"  :placeholder="$t('inputTit.shuru')"/>
                </el-form-item>
                <el-form-item :label="$t('currencySet.text9')" prop="hkContent1">
                    <el-input v-model="fromValue.hkContent1" type="textarea"  :placeholder="$t('inputTit.shuru')"/>
                </el-form-item>
                <el-form-item label="English" prop="enContent1">
                    <el-input v-model="fromValue.enContent1" type="textarea"  :placeholder="$t('inputTit.shuru')"/>
                </el-form-item>
                <h3>{{$t('currencySet.text7')}}</h3>
                <el-form-item :label="$t('currencySet.text8')" prop="zhContent2">
                    <el-input v-model="fromValue.zhContent2" type="textarea"  :placeholder="$t('inputTit.shuru')"/>
                </el-form-item>
                <el-form-item :label="$t('currencySet.text9')" prop="hkContent2">
                    <el-input v-model="fromValue.hkContent2" type="textarea"  :placeholder="$t('inputTit.shuru')"/>
                </el-form-item>
                <el-form-item label="English" prop="enContent2">
                    <el-input v-model="fromValue.enContent2" type="textarea"  :placeholder="$t('inputTit.shuru')"/>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="copyShow = false">{{$t('button.closeQ')}}</el-button>
                    <el-button type="primary" @click="completeFun(form_)">{{$t('button.enter')}}</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,nextTick,getCurrentInstance } from 'vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import copyRules from './copyRules'
    const { $t } = getCurrentInstance().proxy;
    const fromValue = reactive({
        zhContent1: '',
        hkContent1: '',
        enContent1: '',
        zhContent2: '',
        hkContent2: '',
        enContent2: '',
    })
    let post_ = {
        zhContent1: {descriptionType:1,language:'zh',descriptionId:''},
        hkContent1: {descriptionType:1,language:'hk',descriptionId:''},
        enContent1: {descriptionType:1,language:'en',descriptionId:''},
        zhContent2: {descriptionType:2,language:'zh',descriptionId:''},
        hkContent2: {descriptionType:2,language:'hk',descriptionId:''},
        enContent2: {descriptionType:2,language:'en',descriptionId:''},
    }

    const parms_ = ref([])
    const form_ = ref(null)
    const rules = ref(null);rules.value = copyRules.mainRules
    const copyShow = ref(false)
    const completeFun = (form_)=>{
        if (!form_) return
        form_.validate(() => {
            for(let i in post_){
                let obj_ = Object.assign({}, parms_.value, post_[i])
                obj_.content = fromValue[i]
                if(post_[i].descriptionId){
                    Api_.updateChaincoindescriptionObj(obj_)
                }else{
                    Api_.addChaincoindescriptionObj(obj_)
                }
            }
        })
        copyShow.value = false
        ElNotification({
            message: $t('alert.succBind'),
            type: 'success',
        })
    }
    const setData1 = (scope)=>{//获得列表数据 
        copyShow.value = true
        parms_.value = scope
        nextTick(()=>{
            Api_.getChaincoindescriptionList(scope).then(res=>{
                if(res.code === 0){
                    if(res.data.records.length > 0){
                        for(let item of res.data.records){
                            if(item.language === 'zh' && item.descriptionType === 1){
                                post_.zhContent1.descriptionId = item.descriptionId
                                fromValue.zhContent1 = item.content
                            }else if(item.language === 'hk' && item.descriptionType === 1){
                                post_.hkContent1.descriptionId = item.descriptionId
                                fromValue.hkContent1 = item.content
                            }else if(item.language === 'en' && item.descriptionType === 1){
                                post_.enContent1.descriptionId = item.descriptionId
                                fromValue.enContent1 = item.content
                            }else if(item.language === 'zh' && item.descriptionType === 2){
                                post_.zhContent2.descriptionId = item.descriptionId
                                fromValue.zhContent2 = item.content
                            }else if(item.language === 'hk' && item.descriptionType === 2){
                                post_.hkContent2.descriptionId = item.descriptionId
                                fromValue.hkContent2 = item.content
                            }else if(item.language === 'en' && item.descriptionType === 2){
                                post_.enContent2.descriptionId = item.descriptionId
                                fromValue.enContent2 = item.content
                            }
                        }
                    }else{
                        post_ = {
                            zhContent1: {descriptionType:1,language:'zh',descriptionId:''},
                            hkContent1: {descriptionType:1,language:'hk',descriptionId:''},
                            enContent1: {descriptionType:1,language:'en',descriptionId:''},
                            zhContent2: {descriptionType:2,language:'zh',descriptionId:''},
                            hkContent2: {descriptionType:2,language:'hk',descriptionId:''},
                            enContent2: {descriptionType:2,language:'en',descriptionId:''},
                        }
                        fromValue.zhContent1 = ''
                        fromValue.hkContent1 = ''
                        fromValue.enContent1 = ''
                        fromValue.zhContent2 = ''
                        fromValue.hkContent2 = ''
                        fromValue.enContent2 = ''
                    }
                }
            })
        })
    }
    defineExpose({setData1})
</script>
<style lang="scss" scoped>
    .fromClass{
        max-height: 60vh;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 55vh;
        overflow: auto;
        &::-webkit-scrollbar { width: 0 !important }
    }
    :deep(.el-dialog__body) {
       padding-top: 0;
    }
</style>