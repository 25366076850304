import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
const mainRules = {
    zhContent1: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    hkContent1: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    enContent1: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    zhContent2: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    hkContent2: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    enContent2: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }]
}
export default{
    mainRules
}